
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        






















































































































































.campaign-affiliate-reporting-metrics {
  margin-top: -$--clb-space-2;

  h3 {
    margin: 0;
  }

  h5 {
    margin: $--clb-layout-1 0 $--clb-space-2 0;
    font-size: $--clb-font-size-base;
  }

  .filters-summary {
    padding-top: $--clb-space-1;
  }

  .affiliate-reporting-body {
    padding: 0 $--clb-mobile-padding;

    .reporting-metrics-header {
      .reporting-metrics-title {
        margin-top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__group {
          display: flex;
          flex-direction: column;
          flex: 7;
        }

        &__btn-group {
          display: flex;
          flex: 3;
          justify-content: flex-end;
        }

        &__btn-group-back {
          background: $--clb-color-secondary__dark;
          height: 40px;
          width: auto;
          margin-left: $--clb-layout-1 - 1;
        }
      }
    }
  }

  &__tabs {
    margin-top: $--clb-layout-1;

    .el-menu {
      .el-menu-item {
        height: auto;
        line-height: normal;
        padding: $--clb-space-1 $--clb-space-5;
      }
    }
  }

  &__filters {
    display: flex;
    flex-direction: column;

    &-group {
      margin-right: $--clb-layout-1;
    }
  }

  &__table {
    margin-top: $--clb-layout-1;
  }

  @media (min-width: $--sm) {
    .affiliate-reporting-body {
      padding: 0 $--clb-layout-2;
    }

    &__tabs {
      margin-top: $--clb-layout-2;
    }

    &__filters {
      flex-direction: row;
    }
  }
}
